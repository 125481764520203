import PropTypes from 'prop-types';
import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";
import BidsCarousel from "../carousel/bidsCarousel";
import { marketplace_api_prefix } from "../../consts/marketplace-indexer";
import { useQuery } from "@tanstack/react-query";
import { logger } from "../../utils/arbLogger";

const Bids = ({ classes = "py-[50px] mb-0", bgWhite }) => {
  const { data: top_auctions } = useQuery(['top_auctions', process.env.NEXT_PUBLIC_INDEXER_MARKETPLACE_ID], async () => {
    const response = await fetch(process.env.NEXT_PUBLIC_INDEXER_URL + marketplace_api_prefix + process.env.NEXT_PUBLIC_INDEXER_MARKETPLACE_ID + "/top_auctions")
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  })
  logger.debug("TOP_AUCTIONS:", top_auctions)
  return (
    <>
      {top_auctions?.data?.length > 0 && (
        <section className={classes}>
          {/* <!-- Hot Bids --> */}
          {bgWhite && (
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img
                src="/images/gradient_light.jpg"
                alt="gradient"
                className="h-full w-full"
              />
            </picture>
          )}
          <div className="w-8/12 mx-auto">
            <HeadLine
              text="Live Auctions"
              classes="font-display text-jacarta-900 mb-8 text-center text-3xl dark:text-white"
            />

            <div className="relative">
              {/* <!-- Slider --> */}
              <BidsCarousel top_auctions={top_auctions} />
            </div>
          </div>
          {/* <!-- end hot bids --> */}
        </section>
      )}
    </>
  );
};

Bids.propTypes = {
  classes: PropTypes.string,
  bgWhite: PropTypes.bool
};

export default Bids;
