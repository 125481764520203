//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { trpc } from '../../utils/trpc';
import Link from 'next/link';

const Hero = () => {

  const [activeHero, setActiveHero] = useState(0);
  const heroes = trpc.collection.featuredCollection.useQuery({}, { staleTime: 30, queryKey: ["collection.featuredCollection"] });
  const max_heroes = heroes?.data?.collections?.length + 1
  useEffect(() => {
    if (heroes.data?.collections?.length>0) {
      const autoplayTimer = setTimeout(() => {
        handleAutoplay();
      }, 5000); // change 5000 to the number of milliseconds you want each slide to display

      return () => {
        clearTimeout(autoplayTimer);
      };
    }
  }, [activeHero, heroes]);

  //@ts-ignore
  function handleClick(index) {
    setActiveHero(index);
  }
  
  function handleAutoplay() {
    setActiveHero(activeHero => (activeHero + 1) % max_heroes);
  }
  
  const [xStart, setXStart] = useState(null);
  
  const onTouchStart = (e) => {
    setXStart(e.touches[0].clientX);
  };
  
  const onTouchMove = (e) => {
    if (!xStart) {
      return;
    }
  
    const xDiff = xStart - e.touches[0].clientX;
    const yDiff = Math.abs(xStart - e.touches[0].clientY); // Calculate the vertical difference
  
    if (yDiff < Math.abs(xDiff)) {
      // Swipe is primarily horizontal
      setXStart(null);
      if (xDiff > 0) {
        // Swiped left
        setActiveHero(activeHero => (activeHero + 1) % max_heroes);
      } else {
        // Swiped right
        setActiveHero(activeHero => (activeHero - 1 < 0 ? max_heroes - 1 : activeHero - 1));
      }
    }
  };
  


  return (
    <div className="wrapper mt-3 w-full xml:mx-auto mb-15">
      <div className="flex flex-col items-center group dark:border-jacarta-700  block sm:p-[1.1875rem] transition-shadow ">

        {/* Create the images */}
        <div  onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              className="max-w-[1580px] w-full object-cover flex justify-center mt-[120px] sm:mt-[100px] mx-auto mb-4 h-96 ">

          <div className={`${activeHero === 0 ? 'block' : 'hidden'} sm:mx-2 -sm:w-full  object-cover sm:rounded-xxl  overflow-hidden object-cover w-full h-full flex flex-row mt-auto justify-center`}>
            <img src="/images/Dark-ArbiPegs-Home2.png" alt="Image 1" className="h-full w-full object-cover hidden xs:dark:block" />
            <img src="/images/Light-ArbiPegs-Home2.png" alt="Image 1" className="h-full w-full object-cover hidden xs:block dark:hidden" />
            <img src="/images/Dark-ArbiPegs-Home-Phone3.png" alt="Image 1" className="h-full object-cover hidden dark:block xs:hidden xs:dark:hidden" />
            <img src="/images/Light-ArbiPegs-Home-Phone3.png" alt="Image 1" className="h-full object-cover xs:hidden dark:hidden" />

            <div className="z-999 absolute flex group justify-between max-w-[1620px] w-[100%] mt-[175px]">

              <div onClick={() => handleClick(heroes?.data?.collections?.length)} className="z-999 hidden sm:group-hover:block dark:bg-white bg-jacarta-900 border-[10px] hover:scale-110 dark:border-jacarta-900 border-[#ffffff] text-jacarta-900 rounded-full">
                <MdKeyboardArrowLeft className="hover:cursor-pointer bi-arrow-right-short fill-light-base dark:fill-jacarta-900 h-[40px] w-[40px]" />
              </div>

              <div onClick={() => handleClick(1)} className="z-999 hidden sm:group-hover:block dark:bg-white bg-jacarta-900 border-[10px] hover:scale-110 dark:border-jacarta-900 border-[#ffffff] text-jacarta-900 rounded-full">
                <MdKeyboardArrowRight className="hover:cursor-pointer bi-arrow-right-short fill-light-base dark:fill-jacarta-900 h-[40px] w-[40px]" />
              </div>

            </div>
          </div>

          {heroes.data?.collections.map((hero, index) => {
            const {
              title,
              description,
              collectionId,
              avatarUrl,
              bannerUrl,
            } = hero;
            const current_index = index + 1;
            const click_prev_index = index;
            const click_next_index = (current_index != max_heroes - 1 ) ? index + 2 : 0;

            return (
              <div key={`hero-${current_index}`} className={`${activeHero === current_index ? 'block' : 'hidden'} sm:mx-2 -sm:w-full object-cover sm:rounded-xxl overflow-hidden object-cover w-full h-full flex flex-row mt-auto justify-center`}>
                <img src={bannerUrl} alt="Image 1" className="h-full w-full object-cover" />
                <div className="z-999 absolute flex justify-between group max-w-[1620px] w-[100%] mt-[175px]">

                  <div onClick={() => handleClick(click_prev_index)} className="z-999 hidden sm:group-hover:block dark:bg-white bg-jacarta-900 border-[10px] hover:scale-110 dark:border-jacarta-900 border-[#ffffff] text-jacarta-900 rounded-full">
                    <MdKeyboardArrowLeft className="hover:cursor-pointer bi-arrow-right-short fill-light-base dark:fill-jacarta-900 h-[40px] w-[40px]" />
                  </div>

                  <div onClick={() => handleClick(click_next_index)} className="z-999 hidden sm:group-hover:block dark:bg-white bg-jacarta-900 border-[10px] hover:scale-110 dark:border-jacarta-900 border-[#ffffff] text-jacarta-900 rounded-full">
                    <MdKeyboardArrowRight className="hover:cursor-pointer bi-arrow-right-short fill-light-base dark:fill-jacarta-900 h-[40px] w-[40px]" />
                  </div>

                </div>
                
                <Link href={`/collection/${collectionId.toLowerCase()}`} className="absolute -mb-[1px] h-[120px] sm:h-[110px] w-full sm:w-[500px] flex flex-row bg-light-base0 hover:bg-light-base hover:dark:bg-jacarta-700 dark:bg-jacarta-9700 self-end  sm:mb-3 sm:rounded-xl hover:opacity-100">
                
               
                  
                  
                  <div className='min-w-[100px] ml-3 sm:ml-2 mt-[0px] sm:mt-[7px]'>
                    <div className="h-[80px] w-[80px]  mx-auto mt-5  sm:mt-2 rounded-lg">                  
                    <img
                      src={avatarUrl}
                      height="80px"
                      width="80px"
                      alt=""
                      className=" h-[100%] w-[100%] rounded-[0.625rem] object-cover aspect-square  "
                    />
                    </div>
                  </div>
                  <div className="ml-2 w-[250px] mt-[2px] sm:mt-[7px] mx-auto sm:w-[300px]">
                    <p className='pl-1 text-jacarta-400 dark:text-jacarta-300 font-medium mt-6 sm:mt-3'>Featured Collection</p>
                    <p className='pl-1 font-display'  >{title}</p>
                    <p className="pl-1 text-sm  truncate w-[205px]">{description}</p>
                  </div>
                  <div className="h-[80px] w-[80px] mt-9 sm:mt-7">
                      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-arrow-right-short dark:hover:bg-jacarta-600 hover:bg-jacarta-100 rounded-xl hover:scale-110 ease-out duration-300 cursor-pointer" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                      </svg>
                  </div>
                </Link>
              </div>

            )
          })}







        </div>




        <ul className="flex w-50% justify-center w-[45%] mt-2">
          <li
            className={` cursor-pointer mr-2 h-[7px] w-[30%]  rounded-lg px-3 hover:bg-gray-300 ${activeHero === 0 ? 'bg-[#44ace4] text-white py-1' : 'dark:bg-[#1f202d] bg-[#f4f9fc] text-gray-700'
              }`}
            onClick={() => handleClick(0)}
          >
          </li>

          {heroes.data?.collections.map((hero, index) => {
            const click_index = index + 1;
            return (
              <li key={`click-hero-${click_index}`}
                className={` cursor-pointer h-[7px] w-[30%] ml-2 mr-2 rounded-lg px-3 hover:bg-gray-300 ${activeHero === click_index ? 'bg-[#44ace4] text-white py-1' : 'dark:bg-[#1f202d] bg-[#f4f9fc] text-gray-700'
                  }`}
                onClick={() => handleClick(click_index)}
              >
              </li>
            )
          })}


        </ul>
      </div>
    </div>

  );
};

export default Hero;
