//@ts-nocheck
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/legacy/image";
import "tippy.js/dist/tippy.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Link from "next/link";
import { trpc } from '../../utils/trpc';

const Feature_collections_carousel = () => {
  const featuredCollections = trpc.collection.featuredCollection.useQuery({}, {staleTime: 30, queryKey: ["collection.featuredCollection"]});

  return <>
    <Swiper
      modules={[Navigation, Pagination, Scrollbar]}
      breakpoints={{
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        900: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1100: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      }}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      className=" card-slider-4-columns !py-5"
    >
      {featuredCollections.data?.collections.map((item) => {
        const {
          title,
          collectionId,
          avatarUrl,
          ownerId,
          numberOfItems,
          userName,
          floorPrice,
        } = item;

        return (
          <SwiperSlide key={collectionId}>
            <article>
              <div className="group dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2xl border w-[100%] bg-light-base p-[1.1875rem] dark:hover:bg-jacarta-600 hover:bg-jacarta-100">
                <Link href={`/collection/${collectionId.toLowerCase()}`} className="flex space-x-[0.625rem]">

                <figure class="w-[100%] h-full transform transition-transform group-hover:scale-105">
                    <Image
                      src={`${avatarUrl}?h=250`}
                      alt="item 1"
                      className=" rounded-[0.625rem] object-cover aspect-square"
                      width={200}
                      height={200}
                      objectFit="cover"
                      layout="responsive"
                    />
                  </figure>


                </Link>

                <Link
                  href={`/collection/${collectionId.toLowerCase()}`}
                  className="font-display hover:text-accent dark:hover:text-accent text-jacarta-900 mt-8 block text-base dark:text-white">

                  {title}

                </Link>

                <div className="mt-2 flex items-center justify-between font-medium tracking-tight">
                  <div className="flex flex-wrap items-center">

                    <Link href={`/collection/${collectionId.toLowerCase()}`}>
                    <span className="dark:text-jacarta-300 text-jacarta-400 mb-2 mt-2 text-medium flex flex-row">
                     Floor: <img
                        src="/images/arb-icon.png"
                        alt=""
                        className="ml-2 mr-2 w-[20px] h-[20px]"
                      /> {floorPrice} ARB
                  </span>
                  <span className="dark:text-jacarta-300 text-jacarta-400 text-medium flex flex-row">
                     Volume: <img
                        src="/images/arb-icon.png"
                        alt=""
                        className=" ml-2 mr-2 w-[20px] h-[20px]"
                      /> 0 ARB
                  </span>
                  </Link>
                  </div>
                  
                  
                </div>
              </div>
            </article>
          </SwiperSlide>
        );
      })}
    </Swiper>

    {/* <!-- Slider Navigation --> */}
    <div className="group swiper-button-prev absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center border-[3px] dark:border-jacarta-700 border-light-base hover:scale-110 rounded-full dark:bg-white bg-jacarta-900 p-3 text-jacarta-900 text-xl sm:!-left-6 after:hidden">
      <MdKeyboardArrowLeft className="fill-light-base dark:fill-jacarta-900"/>
    </div>
    <div className="group swiper-button-next absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center border-[3px] dark:border-jacarta-700 border-light-base hover:scale-110 rounded-full dark:bg-white bg-jacarta-900 p-3 text-jacarta-900 text-xl sm:!-right-6 after:hidden">
      <MdKeyboardArrowRight className="fill-light-base dark:fill-jacarta-900"/>
    </div>
  </>;
};

export default Feature_collections_carousel;
