//@ts-nocheck
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/legacy/image";
import "tippy.js/dist/tippy.css";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logger } from "../../utils/arbLogger";
import arbDateTimeDiff from "../../utils/display/arbDateTimeDiff";

import { ethers } from "ethers";

const BidsCarousel = ({top_auctions}) => {
  const dispatch = useDispatch();
  const handleclick = () => {
    logger.debug("clicked on ");
  };



  return (<>

    <Swiper
      modules={[Navigation, Pagination, Scrollbar]}
      spaceBetween={30}
      slidesPerView="auto"
      loop={true}
      breakpoints={{
        240: {
          slidesPerView: 1,
        },
        565: {
          slidesPerView: 2,
        },
        1000: {
          slidesPerView: 3,
        },
        1100: {
          slidesPerView: 4,
        },
      }}
      navigation={{
        nextEl: ".bids-swiper-button-next",
        prevEl: ".bids-swiper-button-prev",
      }}
      className=" card-slider-4-columns !py-5"
    >
      {top_auctions?.data?.map((top_auction) => {
        const auction = top_auction.auction
        const { auction_id: auctionId, contract_address: assetContractAddress, end_timestamp: endTimeInSeconds, max_bid_amount, minimum_bid_amount, token_id: assetId } =
          auction;
        logger.debug("TOP_AUCTION", auction)
        const { thumbnail } =
          auction?.alchemy?.media[0];
        const name = auction?.alchemy?.metadata?.name

        const query = { auction: auctionId }

        return (
          <SwiperSlide className="text-white" key={`sw-auction-${auctionId}`}>
            <article>
              <div className="group dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2xl block border bg-light-base text-jacarta-500 dark:hover:bg-jacarta-600 hover:bg-jacarta-100 overflow-hidden">
                <figure className=" overflow-hidden">
                  {/* {`item/${itemLink}`} */}
                  <Link href={{ pathname: `/item/${assetContractAddress}/${assetId}`, query: query }}>
                    <Image
                      src={thumbnail}
                      alt={name}
                      height={230}
                      width={230}
                      layout="responsive"
                      objectFit="cover"
                      className="group-hover:scale-110 duration-300 ease-out  w-full"
                      loading="lazy"
                    />

                  </Link>
                </figure>
                <div className=" p-[1.1875rem]">
                  <div className="mt-4 flex flex-col items-start justify-between ">
                  <span className="dark:border-jacarta-600 mb-4 -ml-1 dark:bg-jacarta-900 bg-white border-jacarta-100 flex items-center whitespace-nowrap rounded-md mr-auto border py-1 px-2">
                      <Tippy content={<span>ARB</span>}>
                        <img
                          src="/images/arb-icon.png"
                          alt=""
                          className="w-3 h-3 mr-1"
                        />
                      </Tippy>

                      <span className="text-accent text-sm font-medium tracking-tight">
                        {(parseFloat(ethers.utils.formatUnits(max_bid_amount ? max_bid_amount : minimum_bid_amount)).toFixed(2))} ARB
                      </span>
                    </span>
                    <Link href={{ pathname: `/item/${assetContractAddress}/${assetId}`, query: query }}>

                      <span className="font-display hover:text-accent dark:hover:text-accent text-jacarta-900 text-base dark:text-white">
                        {name}
                      </span>

                    </Link>
                  </div>

                  <div className="mt-8 flex-col items-center justify-between dark:text-jacarta-300 text-jacarta-400 font-medium">
                    <h2 className="flex flex-nowrap overflow-hidden line-clamp-2"> 
                      <p className="flex-none">Ends In:</p>
                      <p className="text-accent ml-1 flex-none">{arbDateTimeDiff(endTimeInSeconds)}</p>
                    </h2>
                  </div>
                </div>
              </div>
            </article>
          </SwiperSlide>
        );
      })}
    </Swiper>
    <div className="group bids-swiper-button-prev absolute !top-1/2 !-left-4 z-10 -mt-6 flex opacity-20 hover:opacity-100 !h-12 !w-12 cursor-pointer items-center justify-center border-[3px] dark:border-jacarta-700 border-light-base hover:scale-110 rounded-full dark:bg-white bg-jacarta-900 p-3 text-jacarta-900 text-xl sm:!-left-6 after:hidden">
      <MdKeyboardArrowLeft className="fill-light-base dark:fill-jacarta-900" />
    </div>
    <div className="group bids-swiper-button-next absolute !top-1/2 !-right-4 z-10 -mt-6 flex opacity-20 hover:opacity-100 !h-12 !w-12 cursor-pointer items-center justify-center border-[3px] dark:border-jacarta-700 border-light-base hover:scale-110 rounded-full dark:bg-white bg-jacarta-900 p-3 text-jacarta-900 text-xl sm:!-right-6 after:hidden">
      <MdKeyboardArrowRight className="fill-light-base dark:fill-jacarta-900" />
    </div>
    </>
);
};

export default BidsCarousel;
