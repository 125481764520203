import Hero from "./hero/Hero";
import Bids from "./bids/bids";
import Top_collection from "./collectrions/top_collection";
import Tranding_category from "./categories/tranding_categories";
import HeadLine from "./headLine";
import NewseLatter from "./blog/newseLatter";
import Feature_collections from "./collectrions/Feature_collections";
import Browse_category from "./categories/Browse_category";
import Hero_3 from "./hero/hero_3";
import ItemsTabs from "./tabs/Tabs";

export {
  Hero,
  Bids,
  Top_collection,
  Tranding_category,
  HeadLine,
  NewseLatter,
  Feature_collections,
  Browse_category,
  Hero_3,
  ItemsTabs,
};
