import { addressZero } from "../../../consts/contracts"
import shortEthAddress from "../../../utils/display/shortEthAddress"

const MintSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star mt-[3px] mr-[2px]" viewBox="0 0 16 16">
            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
        </svg>
    )
}

const TransferSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-right mt-[3px] mr-[2px]" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
        </svg>
    )
}

const SaleSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bag-check mt-[3px] mr-[2px]" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
        </svg>
    )
}

const ListingSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tag mt-[3px] mr-[2px]" viewBox="0 0 16 16">
            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
        </svg>
    )
}

const BidSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-plus mt-[3px] mr-[2px]" viewBox="0 0 16 16">
            <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </svg>
    )
}

//TODO Convert block to timestamp
//@ts-ignore
const HistoryEvent = ({ historyEvent }
) => {
    let historyEventType = "";
    let historyEventClassName = "";
    let historyEventTextA = "";
    let historyEventTextB = "";
    let historyEventBlockNumber = "";
    let historyEventAgo = "";
    if (historyEvent.eventName && historyEvent.eventName == "Transfer") {
        if (historyEvent.data.from == addressZero) {
            historyEventType = "Mint";
            historyEventTextA = "To " + shortEthAddress(historyEvent.data.to)
            historyEventClassName = "mb-2 text-md font-medium border hover:scale-110 rounded-full pr-[10px] pl-[10px] border-[#ffd44f] text-[#ffd44f] p-1 bg-[#ffebae] bg-opacity-30 flex flex-row";
        }
        else {
            historyEventType = "Transfer";
            historyEventTextA = "From " + shortEthAddress(historyEvent.data.from)
            historyEventTextB = "To " + shortEthAddress(historyEvent.data.to)
            historyEventClassName = "mb-2 text-md font-medium border hover:scale-110 rounded-full pr-[10px] pl-[10px] border-[#3d85c6] text-[#3d85c6] p-1 bg-[#7bbaf4] bg-opacity-30 flex flex-row";
        }
        historyEventBlockNumber = historyEvent.transaction.blockNumber;
        historyEventAgo = historyEvent.transaction.blockNumber;

    }
    return (
        <div key={historyEventBlockNumber} className="flex flex-row w-[100%] bg-light-base dark:bg-jacarta-800 dark:border-jacarta-600 border-jacarta-100 border justify-between p-5 h-[100px] mt-3 rounded-xl mb-1 xml:pl-[50px] xml:pr-[50px]">

            < div className="flex flex-col">
                <div className={historyEventClassName}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star mt-[3px] mr-[5px]" viewBox="0 0 16 16">
                        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                    {historyEventType}
                </div>
                <div className="text-sm text-jacarta-400">{historyEventAgo} ago</div>
            </div>
            <div className="flex flex-col">
                <div className="mb-2 text-md">--</div>
                <div className="text-sm">--</div>
            </div>
            <div className="flex flex-col">

                <div className="mb-2 text-m font-medium hover:text-accent">
                    {historyEventTextA && (historyEventTextA != "") && <>{historyEventTextA}</>}
                </div>


                <div className="text-m font-medium hover:text-accent">
                    {historyEventTextB && (historyEventTextB != "") &&
                        <>{historyEventTextB}</>}
                </div>

            </div>
        </div>)
}

export default HistoryEvent;

/* 
<div className="flex flex-row w-[100%] bg-light-base dark:bg-jacarta-800 justify-between p-5 h-[100px] mt-3 rounded-xl mb-1 xml:pl-[50px] xml:pr-[50px]">
<div className="flex flex-col">
    <div className="mb-2 text-md border rounded-full pr-[10px] pl-[10px] border-[#1d035e] text-[#1d035e] bg-[#7557c4] bg-opacity-60 flex flex-row"> 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag-check mt-[3px] mr-[2px]" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
        </svg>
        Sale
    </div>
    <div className="text-sm">.. ago</div>
</div>
<div className="flex flex-col">
    <div className="mb-2 text-md">--</div>
    <div className="text-sm">--</div>
</div>
<div className="flex flex-col">
    <div className="mb-2 text-md">from 0x000..000</div>
    <div className="text-sm">To 0x8as..asd8</div>
</div>
</div>

<div className="flex flex-row w-[100%] bg-light-base dark:bg-jacarta-800 justify-between p-5 h-[100px] mt-3 rounded-xl mb-1 xml:pl-[50px] xml:pr-[50px]">
<div className="">
    <div className="mb-2 text-md border rounded-full pr-[10px] pl-[10px] border-[#a90202] text-[#a90202] bg-[#ff3b3b] bg-opacity-70 flex flex-row"> 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag mt-[3px] mr-[2px]" viewBox="0 0 16 16">
            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"/>
            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"/>
        </svg>
        Listing
    </div>
</div>
<div className="flex flex-col">
    <div className="mb-2 text-md">--</div>
    <div className="text-sm">--</div>
</div>
<div className="flex flex-col">
    <div className="mb-2 text-md">from 0x000..000</div>
</div>
</div>

<div className="flex flex-row w-[100%] bg-light-base dark:bg-jacarta-800 justify-between p-5 h-[100px] mt-3 rounded-xl mb-1 xml:pl-[50px] xml:pr-[50px]">
<div className="flex flex-col">
    <div className="mb-2 text-md border rounded-full pr-[10px] pl-[10px] border-[#2d6416] text-[#2d6416] bg-[#8cf75d] bg-opacity-60 flex flex-row"> 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-plus mt-[3px] mr-[2px]" viewBox="0 0 16 16">
            <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z"/>
            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
        Bid
    </div>
    <div className="text-sm">.. ago</div>
</div>
<div className="flex flex-col">
    <div className="mb-2 text-md">--</div>
    <div className="text-sm">--</div>
</div>
<div className="">
    <div className="mb-2 text-md">from 0x000..000</div>
</div> */