import React, { FC, useState } from 'react';
import Link from 'next/link';
import shortEthAddress from '../../../utils/display/shortEthAddress';
import arbDateTimeDiff from '../../../utils/display/arbDateTimeDiff';
import SpinningButton from '../../arbipegs/button/SpinningTransactionButton';
import { ThirdwebSDK } from '@thirdweb-dev/sdk';
import { logger } from '../../../utils/arbLogger';
import { useAddress, useSigner } from '@thirdweb-dev/react';

const OfferTab: FC<{offers: [], isOwner: boolean }> = ({ offers, isOwner }) => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const signer = useSigner();
    const walletAddress = useAddress();
    //@ts-ignore
    const cancelOffer = async (offerId) => {
        setButtonLoading(true);
        try {
            //@ts-ignore
            const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
                clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
            });
            logger.trace("CANCEL OFFER FOR", offerId);
            //@ts-ignore
            const contractMarketplace = await sdk.getContract(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS);
            const tx = await contractMarketplace.offers.cancelOffer(offerId);
            const res = await fetch("/api/marketplace/ping", {
                method: "POST",
                body: JSON.stringify({}),
            }).then((res) => res.json());
            logger.debug("Reindex Marketplace", res);
            setButtonLoading(false);
        }
        catch (e) {
            setButtonLoading(false);
            logger.info("Cancel offer Transaction didn't complete:", e);
        }
    }

    //@ts-ignore
    const acceptOffer = async (offerId) => {
        setButtonLoading(true);
        try {
            //@ts-ignore
            const sdk = ThirdwebSDK.fromSigner(signer, process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, {
                clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
            });
            logger.trace("ACCEPT OFFER FOR", offerId);
            //@ts-ignore
            const contractMarketplace = await sdk.getContract(process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS);
            const tx = await contractMarketplace.offers.acceptOffer(offerId);
            const res = await fetch("/api/marketplace/ping", {
                method: "POST",
                body: JSON.stringify({}),
            }).then((res) => res.json());
            logger.debug("Reindex Marketplace", res);
            setButtonLoading(false);
        }
        catch (e) {
            setButtonLoading(false);
            logger.info("Cancel auction Transaction didn't complete:", e);
        }
    }
    return <>
        {/* <!-- Offers --> */}
        <div
            className="tab-pane fade show active mt-7 mb-7 min-w-[514px] max-w-[770px]"
            id="offers"
            role="tabpanel"
            aria-labelledby="offers-tab"
        >
            <div
                role="table"
                className="scrollbar-custom dark:bg-jacarta-700 grid w-full grid-cols-4 rounded-xl bg-light-base text-sm dark:text-white"
            >
                <div className="contents" role="row">
                    <div
                        className="dark:bg-jacarta-800 bg-light-base rounded-tl-large sticky top-0 py-2 px-4"
                        role="columnheader"
                    >
                        <span className="text-jacarta-900 font-medium dark:text-white w-full overflow-hidden text-md">
                            Price
                        </span>
                    </div>
                    <div
                        className="dark:bg-jacarta-800 bg-light-base sticky top-0 py-2 px-4"
                        role="columnheader"
                    >
                        <span className="text-jacarta-900 dark:text-white font-medium w-full overflow-hidden text-md">
                            Expiration
                        </span>
                    </div>
                    <div
                        className="dark:bg-jacarta-800 bg-light-base rounded-tr-large sticky top-0 py-2 px-4"
                        role="columnheader"
                    >
                        <span className="text-jacarta-900 font-medium dark:text-white w-full overflow-hidden text-md">
                            From
                        </span>
                    </div>
                    <div
                        className="dark:bg-jacarta-800 bg-light-base rounded-tl-large sticky top-0 py-2 px-4"
                        role="columnheader"
                    >
                        <span className="text-jacarta-900 font-medium dark:text-white w-full overflow-hidden text-md">
                        </span>
                    </div>

                </div>
                {offers?.map((offer: any) => {
                    const { currencyValue: price, id, endTimeInSeconds: expiration, offerorAddress: user } = offer;
                    return (
                        <div className="contents" role="row" key={id}>
                            <div
                                className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap border-t py-4 px-4"
                                role="cell"
                            >
                                <span className="-ml-1 mr-2" data-tippy-content="ETH">
                                    <img src="/images/arb-icon.png" alt="" width={20} height={20} />
                                </span>
                                <span className="text-accent text-sm font-medium tracking-tight">
                                    {price.displayValue}
                                </span>
                            </div>
                            <div
                                className="dark:border-jacarta-600 border-jacarta-100 text-jacarta-400 flex items-center border-t py-4 px-4"
                                role="cell"
                            >
                                in {arbDateTimeDiff(expiration)}
                            </div>
                            <div
                                className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                                role="cell"
                            >
                                <Link href="#" className=" text:jacarta-900 hover:text-accent dark:hover:text-accent font-medium dark:text-white">
                                    {shortEthAddress(user)}
                                </Link>
                            </div>
                            <div className='dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4' role="cell">
                                {!buttonLoading ?
                                    (<>
                                    {isOwner &&
                                        <button
                                            type="button"
                                            className="py-1 px-2 font-semibold text-white bg-accent text-center hover:bg-accent-dark rounded-sm text-xs rounded-xl transition-all"
                                            onClick={() => acceptOffer(id)}
                                        >
                                            Accept Offer
                                        </button> }
                                        {(walletAddress==user) &&
                                        <button
                                            type="button"
                                            className="py-1 px-2 font-semibold text-white bg-accent text-center hover:bg-accent-dark rounded-sm text-xs rounded-xl transition-all"
                                            onClick={() => cancelOffer(id)}
                                        >
                                            Cancel Offer
                                        </button> }
                                    </>) :
                                    <SpinningButton />
                                }
                            </div>

                        </div>
                    );
                })}
            </div>
        </div>
    </>;
};

export default OfferTab;
