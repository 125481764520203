import HeadLine from "../headLine";
import { newseLatterData } from "../../data/newseLatterData";
import Image from "next/legacy/image";
import Link from "next/link";

const NewseLatter = ({ bgWhite = true }) => {
  return (
    <section className="dark:bg-jacarta-900 relative py-24">
      

      <div className="container">
        <HeadLine
          text="Stay in the game"
          classes="font-display text-jacarta-700 mb-5 text-center text-2xl dark:text-white"
        />              

        <p className="dark:text-jacarta-300 text-jacarta-400 mx-auto max-w-2xl text-center text-2xl text-base font-medium">
        Join our official Discord server to stay in the loop with our newest releases, NFT drops, and tips and tricks for navigating on ArbiPegs.
        </p>

      <div className="flex flex-col md:flex-row justify-center md:space-x-5" >
        <div className=" text-center ">
      
          <Link href="/explore">
            <button
            className="hover:bg-accent-dark hover:scale-105 font-bold text-sm bg-accent mt-6 right-2 w-[220px] rounded-xl px-8 py-4 text-white transition-all">
              Explore Marketplace
            </button>
            </Link>
        </div>
        <div className=" text-center "><Link href="https://discord.gg/TMyfRw5S3Q">
            <button
            className="hover:bg-accent-dark hover:scale-105 font-bold text-sm bg-accent mt-6 right-2 rounded-xl px-8 py-4 text-white w-[220px] transition-all">
              Join Discord
            </button>
            </Link>
            </div>
            </div>
      </div>
    </section>
  );
};

export default NewseLatter;
