import React, { FC, useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Offers from "./tabsContent/Offers";
import History from "./tabsContent/History";

const HistoryOffers: FC<{ auctionId?: string, offers?: [], isOwner: boolean }> = ({ auctionId, offers, isOwner }) => {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    // Set the active tab to 2 (Offers) when the component mounts
    setActiveTab(2);
  }, []);

    //@ts-ignore
    function handleTabClick(tabIndex: number) {
     setActiveTab(tabIndex);
    }


  const [tabsActive, setTabsActive] = useState(1);
  const tabsHeadText = [
    {
      id: 1,
      text: "Offers",
      icon: "Offers",
    },
    {
      id: 2,
      text: "history",
      icon: "history",
    },
  ];

  return (
    <div className="mb-2">
      <div className="">
        <Tabs className="min-w-fit tabs">
          <TabList className="nav nav-tabs flex flex-row justify-start">
            {tabsHeadText.map(({ id, text, icon }) => {
              return (
                <Tab className="nav-item" key={id}>
                  <button
                    className={
                      tabsActive === id
                        ? " hover:text-jacarta-900 bg-jacarta-100 dark:bg-jacarta-600 rounded-xl dark:text-white text-black relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white active"
                        : " hover:text-jacarta-900 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                    }
                    onClick={() => setTabsActive(id)}
                  >
                    <span className="font-display text-base font-medium">
                      {text}
                    </span>
                  </button>
                </Tab>
              );
            })}
          </TabList>

          {/* Switch the order of TabPanel components */}
          <TabPanel>
          <div className="">
           {offers ? <Offers offers={offers} isOwner={isOwner} /> : null}
          </div>
          </TabPanel>

          <TabPanel className="tab-content">
            <History />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default HistoryOffers;
