import React, { useEffect, useState } from 'react';
import { logger } from '../../../utils/arbLogger';
import { ThirdwebSDK } from '@thirdweb-dev/sdk';
import { useRouter } from 'next/router';
import HistoryEvent from '../../arbipegs/history/HistoryEvent';
import { getThidWebChain } from '../../../utils/alchemyWrapper';



const History = () => {
    const router = useRouter();
    const collectionAddress = router.query.item;
    const tokenId = router.query.token;
    const [history, setHistory] = useState<any>([]);
    const getTokenHistory = async () => {
        try {
            //@ts-ignore
            const chain = getThidWebChain(collectionAddress)
            const sdk = new ThirdwebSDK(chain, {
                clientId: process.env.NEXT_PUBLIC_THIRDWEB_API_KEY
              });
            const contractCollection = await sdk.getContract(
                //@ts-ignore
                collectionAddress, "nft-collection");
            const options = {
                filters: {
                    tokenId: tokenId
                },
            };
            const events = await contractCollection.events.getEvents(
                "Transfer", options
            );
            logger.debug("HISTORY", events)
            setHistory(events)
        }
        catch (e) {
            logger.error(e)
        }
    }

    useEffect(() => {
        logger.trace("History use effect")
        if (collectionAddress && tokenId) {
            getTokenHistory()
        }
    }, [collectionAddress, tokenId]);
    return (
        <div className="">
            {/* <div className="sm:flex">
                <div className="mt-7 mb-2 flex flex-row">
                    <button className="ml-1 mr-1 dark:border-jacarta-600 dark:bg-jacarta-700 hover:bg-jacarta-100 dark:hover:bg-jacarta-600 group border-jacarta-100 font-display text-jacarta-900 flex h-9 items-center rounded-lg border bg-light-base px-4 text-sm font-semibold transition-colors hover:border-transparent dark:text-white dark:hover:border-transparent dark:hover:text-white">All</button>
                    <button className="ml-1 mr-1 dark:border-jacarta-600 dark:bg-jacarta-700 hover:bg-jacarta-100 dark:hover:bg-jacarta-600 group border-jacarta-100 font-display text-jacarta-900 flex h-9 items-center rounded-lg border bg-light-base px-4 text-sm font-semibold transition-colors hover:border-transparent dark:text-white dark:hover:border-transparent dark:hover:text-white">Mint</button>
                    <button className="ml-1 mr-1 dark:border-jacarta-600 dark:bg-jacarta-700 hover:bg-jacarta-100 dark:hover:bg-jacarta-600 group border-jacarta-100 font-display text-jacarta-900 flex h-9 items-center rounded-lg border bg-light-base px-4 text-sm font-semibold transition-colors hover:border-transparent dark:text-white dark:hover:border-transparent dark:hover:text-white">Transfer</button>
                </div>
                <div className="sm:mt-7 mt-2 mb-2 flex flex-row">
                    <button className="ml-1 mr-1 dark:border-jacarta-600 dark:bg-jacarta-700 hover:bg-jacarta-100 dark:hover:bg-jacarta-600 group border-jacarta-100 font-display text-jacarta-900 flex h-9 items-center rounded-lg border bg-light-base px-4 text-sm font-semibold transition-colors hover:border-transparent dark:text-white dark:hover:border-transparent dark:hover:text-white">Sale</button>
                    <button className="ml-1 mr-1 dark:border-jacarta-600 dark:bg-jacarta-700 hover:bg-jacarta-100 dark:hover:bg-jacarta-600 group border-jacarta-100 font-display text-jacarta-900 flex h-9 items-center rounded-lg border bg-light-base px-4 text-sm font-semibold transition-colors hover:border-transparent dark:text-white dark:hover:border-transparent dark:hover:text-white">Listing</button>
                    <button className="ml-1 mr-1 dark:border-jacarta-600 dark:bg-jacarta-700 hover:bg-jacarta-100 dark:hover:bg-jacarta-600 group border-jacarta-100 font-display text-jacarta-900 flex h-9 items-center rounded-lg border bg-light-base px-4 text-sm font-semibold transition-colors hover:border-transparent dark:text-white dark:hover:border-transparent dark:hover:text-white">Bid</button>
                </div>
            </div> */}
            <div>
                {history?.map((historyEvent: any, index: number) => {
                    return (
                        <HistoryEvent key={`history-${historyEvent.transaction.blockNumber}-${index}`} historyEvent={historyEvent} />
                    )
                }
                )}


            </div>
        </div >
    );
}

export default History;
